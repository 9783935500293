import React, {useEffect} from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/SEO';

const HEADER_HEIGHT = 68;

function getChartId(id) {
    return `saved-chart-${id}`;
}

const SAVED_CHART_IDS_KEY = 'SAVED_CHART_IDS';

const StorageManager = {
    save: function(chartData) {
        const chartId = getChartId(chartData.id);
        localStorage.setItem(chartId, JSON.stringify(chartData));

        let chartIds = this._getSavedChartIds();
        chartIds.push(chartId);

        localStorage.setItem(SAVED_CHART_IDS_KEY, JSON.stringify(chartIds));
    },
    remove: function(removeId) {
        const chartId = getChartId(removeId);

        localStorage.removeItem(chartId);

        let savedChartIds = this._getSavedChartIds();
        savedChartIds = savedChartIds.filter((id) => chartId !== id);

        localStorage.setItem(SAVED_CHART_IDS_KEY, JSON.stringify(savedChartIds));
    },
    get: function(id) {
        const chartData = localStorage.getItem(getChartId(id));

        return JSON.parse(chartData);
    },
    getAll: function() {
        let savedItems = localStorage.getItem(SAVED_CHART_IDS_KEY);
        if (!savedItems) {
            savedItems = '[]';
        }

        savedItems = JSON.parse(savedItems);

        const saves = [];
        for (const itemKey of savedItems) {
            const chartData = localStorage.getItem(itemKey)
            if (chartData) {
                saves.push(JSON.parse(chartData));
            }
        }

        return saves;
    },
    _getSavedChartIds: function() {
        let savedItems = localStorage.getItem(SAVED_CHART_IDS_KEY);
        if (!savedItems) {
            savedItems = '[]';
        }

        savedItems = JSON.parse(savedItems);

        return savedItems;
    }
};

function ChartPage() {
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);

        const updateFrequencyInMs = 900000; // 15 mins

        new TradingView.widget({
            symbol: urlSearchParams.has('view') ? urlSearchParams.get('view') : 'PSE',
            interval: 'D',
            container_id: "tv_chart_container",
            library_path: '/tradingview/charting_library/',
            autosize: true,
            // UDFCompatibleDatafeed takes the URL of the endpoint to fetch the data
            datafeed: new Datafeeds.UDFCompatibleDatafeed("/datafeed", updateFrequencyInMs),
            save_load_adapter: {
                getAllCharts: function () {
                    return Promise.resolve(StorageManager.getAll());
                },
                removeChart: function (id) {
                    StorageManager.remove(id);

                    return Promise.resolve();
                },
                saveChart: function (chartData) {
                    if (!chartData.id) {
                        chartData.id = Math.random().toString();
                    } else {
                        this.removeChart(chartData.id);
                    }

                    chartData.timestamp = new Date().valueOf() / 1000;

                    StorageManager.save(chartData);

                    return Promise.resolve(chartData.id);
                },
                getChartContent: function (id) {
                    return Promise.resolve(StorageManager.get(id).content);
                },
            }
        });
    }, []);

    return (
        <Layout>
            <SEO title="Chart" description="PSE Charting"/>
            <div id='tv_chart_container' style={{height: `calc(100vh - ${HEADER_HEIGHT}px)`}}></div>
        </Layout>
    )
}

export default ChartPage;
